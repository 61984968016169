import {
  Auth,
  browserSessionPersistence,
  getAuth,
  IdTokenResult,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailLink,
  UserCredential,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  linkWithCredential,
  unlink,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import {
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  Subject,
  Subscription,
} from "rxjs";
import { map, shareReplay, switchMap, takeUntil } from "rxjs/operators";
import { GoogleAuthProvider } from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import {
  getFirestore,
  Firestore,
  doc as docRef,
  collection as collectionRef,
  CollectionReference,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { doc } from "rxfire/firestore";
import { BlastUser, UserRole, BlastUserData, UserClaim } from "@blast/models";
import { SharedBrickLocatorMap } from "../SharedBrick";
import { Locator } from "@blast/foundations";
import { ToastService } from "./toast.service";
import { DialogService } from "./dialog.service";
import { AnalyticsService } from "./analytics.service";
import { authState } from "rxfire/auth";

export interface LoginOptions {
  scoped: boolean;
}

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier;
  }
}

class AuthService {
  private _auth: Auth;
  private _firestore: Firestore;
  private _user$: Observable<BlastUser | null>;
  private _leader$: Observable<boolean>;
  private _leader_basic$: Observable<boolean>;
  private _admin$: Observable<boolean>;
  private _loggedIn$: Observable<boolean>;
  private _tokenResult$: Observable<IdTokenResult | null>;
  private _provider: GoogleAuthProvider;
  private _resolvedEmailSignIn = new Subject<boolean>();
  private _resolvedRedirect = new Subject<boolean>();
  private _userSubscription: Subscription;
  private _destroy = new Subject();
  private _claims$: Observable<UserClaim[]>;

  constructor(
    app: FirebaseApp,
    private _toastService: ToastService,
    private _dialogService: DialogService,
    private _analyticsService: AnalyticsService
  ) {
    this._auth = getAuth(app);
    this._auth.useDeviceLanguage();
    this._firestore = getFirestore(app);
    this._provider = new GoogleAuthProvider();

    this._user$ = combineLatest([
      authState(this._auth).pipe(takeUntil(this._destroy)),
      this._resolvedEmailSignIn,
      this._resolvedRedirect,
    ]).pipe(
      map(([user]) => user),
      switchMap((user) => {
        if (user) {
          const userCollection = collectionRef(this._firestore, "users");
          const userDoc = docRef(userCollection, user.uid);
          return doc(userDoc).pipe(
            map((blastUser) => {
              if (blastUser) {
                return {
                  ...user,
                  ...(blastUser.data() as BlastUserData),
                };
              } else {
                return null;
              }
            })
          );
        } else {
          return of(null);
        }
      }),
      shareReplay(1)
    );

    this._loggedIn$ = this._user$.pipe(map((user) => !!user));

    this._tokenResult$ = authState(this._auth).pipe(
      switchMap((user) => {
        if (user) {
          return from(user.getIdTokenResult(true));
        }
        return of(null);
      }),
      shareReplay(1)
    );

    this._claims$ = this._tokenResult$.pipe(
      map((token) =>
        Object.entries(token?.claims ?? {})
          .filter(([_, value]) => value === true)
          .map(([claim]) => claim as UserClaim)
      )
    );

    this._admin$ = this._tokenResult$.pipe(
      map((token) => Boolean(token?.claims[UserRole.ADMIN]))
    );
    this._leader$ = this._tokenResult$.pipe(
      map((token) => Boolean(token?.claims[UserRole.LEADER]))
    );
    this._leader_basic$ = this._tokenResult$.pipe(
      map(
        (token) =>
          Boolean(token?.claims[UserRole.LEADER_BASIC]) ||
          Boolean(token?.claims[UserRole.LEADER])
      )
    );

    this._userSubscription = this._user$.subscribe();

    void this._handleRedirectResult();

    void this._handleSignInWithEmail();
  }

  private async _handleRedirectResult() {
    const credential = await getRedirectResult(this._auth);
    this._resolvedRedirect.next(true);
    if (credential) {
      await this.setUserDoc(credential);
    }
  }

  private async _handleSignInWithEmail() {
    if (isSignInWithEmailLink(this._auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = await this._dialogService.showPrompt(
          "Please confirm your email",
          "email"
        );
      }
      try {
        const credential = await signInWithEmailLink(
          this._auth,
          email!,
          window.location.href
        );
        this._analyticsService.log("login", { method: "email" });
        const { origin } = new URL(window.location.href);
        window.history.replaceState({}, "", origin);
        await this.setUserDoc(credential);
        window.localStorage.removeItem("emailForSignIn");
      } catch (e) {
        this._toastService.showErrorToast(
          "We could not confirm your identity. If this issue persists please contact wchsblast@gmail.com"
        );
      }
    }
    this._resolvedEmailSignIn.next(true);
  }

  get claims(): Observable<UserClaim[]> {
    return this._claims$;
  }

  get admin(): Observable<boolean> {
    return this._admin$;
  }

  get leader(): Observable<boolean> {
    return this._leader$;
  }

  get leaderBasic(): Observable<boolean> {
    return this._leader_basic$;
  }

  get loggedIn(): Observable<boolean> {
    return this._loggedIn$;
  }

  get user() {
    return this._user$;
  }

  logout() {
    return this._auth.signOut();
  }

  async sendSignInLinkToEmail(email: string) {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(this._auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      this._destroy.next(true);
      this._userSubscription.unsubscribe();
      this._toastService.showSuccessToast(
        "We sent a sign-in link to your email!"
      );
    } catch (e) {
      console.error(e);
      this._toastService.showErrorToast(
        "We're having trouble signing in with email. Please try again or contact us at wchsblast@gmail.com if this issue persists."
      );
      this._analyticsService.log("send-sign-in-link-failure");
    }
  }

  async linkPhoneNumber(phoneNumber: string) {
    const user = this._auth.currentUser;
    if (!user) {
      this._toastService.showErrorToast(
        "Must be signed in to link phone number!"
      );
      return false;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        this._auth,
        "sign-in-button",
        {
          size: "invisible",
        }
      );

      const confirmationResult = await signInWithPhoneNumber(
        this._auth,
        phoneNumber,
        window.recaptchaVerifier!
      );
      const code = await this._dialogService.showPrompt(
        "Please provide the confirmation code sent to your phone number",
        "number"
      );
      const credential = PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        code
      );
      await linkWithCredential(user, credential);

      await setDoc(
        docRef(this._firestore, `users/${user.uid}`),
        {
          phoneNumber,
        },
        { merge: true }
      );
      this._toastService.showSuccessToast("Successfully linked phone number!");
    } catch (e) {
      console.error(e);
      window.recaptchaVerifier?.clear();
      window.recaptchaVerifier = undefined;
      this._toastService.showErrorToast("Could not sign-in with phone number!");
      return false;
    }
    window.recaptchaVerifier?.clear();
    window.recaptchaVerifier = undefined;
    return true;
  }

  async unlinkPhoneNumber() {
    const user = this._auth.currentUser;
    if (!user) {
      this._toastService.showErrorToast(
        "User must be signed in to unlink phone number!"
      );
      return;
    }
    const phoneProvider = user.providerData.find(
      (provider) => provider.providerId === "phone"
    );
    if (!phoneProvider) {
      this._toastService.showErrorToast(
        "No phone number is attached to this account!"
      );
      return;
    }
    try {
      await unlink(user, phoneProvider.providerId);
      this._toastService.showSuccessToast(
        "Successfully unlinked your phone number!"
      );
      await setDoc(
        docRef(this._firestore, `users/${user.uid}`),
        { phoneNumber: null },
        { merge: true }
      );
    } catch (e) {
      console.error(e);
      this._toastService.showErrorToast(
        "There was an issue unlinking your phone number!"
      );
    }
  }

  async login() {
    try {
      await setPersistence(this._auth, browserSessionPersistence);
      this._provider.setCustomParameters({
        prompt: "select_account",
      });
      await signInWithRedirect(this._auth, this._provider);
    } catch (e) {
      if (e instanceof Error && e.message.includes("popup-blocked")) {
        this._toastService.showErrorToast(
          "Failed launch signin popup. Please try again. Otherwise, make sure popups are enabled or try a different browser."
        );
        this._analyticsService.log("google-auth-popup-failure");
      } else if (
        e instanceof Error &&
        e.message.includes("auth/admin-restricted-operation")
      ) {
        this._toastService.showErrorToast(
          "Not accepting new account creations at this time! Please contact us to get an account."
        );
      }

      console.error(e);
    }
  }

  async setUserDoc(credential: UserCredential) {
    try {
      const userCollection = collectionRef(
        this._firestore,
        "users"
      ) as CollectionReference<BlastUserData>;
      const { uid, displayName, photoURL, email, phoneNumber } =
        credential.user;
      const userDoc = docRef(userCollection, credential.user.uid);
      await runTransaction(this._firestore, async (transation) => {
        const currentUserDoc = await transation.get(userDoc);
        if (currentUserDoc.exists()) {
          const { displayName } = currentUserDoc.data();
          await transation.set(
            userDoc,
            {
              uid,
              displayName,
              photoURL,
              email,
              phoneNumber,
            },
            { merge: true }
          );
        } else {
          await transation.set(
            userDoc,
            {
              uid,
              displayName,
              photoURL,
              email,
              phoneNumber,
            },
            { merge: true }
          );
        }
      });
    } catch (e) {
      this._analyticsService.log("set-user-doc-failure");
      console.error("Cannot set user doc", e);
    }
  }

  async patchUserDoc(user: Partial<BlastUser>) {
    if (user.uid) {
      return setDoc(docRef(this._firestore, `users/${user.uid}`), user, {
        merge: true,
      });
    } else {
      const { uid } = (await firstValueFrom(this.user)) ?? {};
      if (uid) {
        return setDoc(docRef(this._firestore, `users/${uid}`), user, {
          merge: true,
        });
      } else {
        this._analyticsService.log("set-user-doc-failure");
        console.error("Cannot obtain uid to patch user doc");
      }
    }
  }

  autoAssignedRoles(email: string | null): Partial<Record<UserRole, boolean>> {
    const autoAssignedRoles: Partial<Record<UserRole, boolean>> = {};
    if (!email) {
      return autoAssignedRoles;
    }
    const LEADERS: string[] = [];

    const ADMINS: string[] = [];

    if (LEADERS.some((leader) => email === leader)) {
      autoAssignedRoles[UserRole.LEADER] = true;
    }

    if (ADMINS.some((admin) => email === admin)) {
      autoAssignedRoles[UserRole.ADMIN] = true;
    }
    return autoAssignedRoles;
  }
}

export async function initializeAuthService(
  locator: Locator<SharedBrickLocatorMap>
) {
  const [app, toastService, dialogService, analytics] = await locator.getAll([
    "App",
    "ToastService",
    "DialogService",
    "AnalyticsService",
  ]);

  return new AuthService(app, toastService, dialogService, analytics);
}

export type { AuthService };
