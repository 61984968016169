import { Locator } from "@blast/foundations";
import { SharedBrickLocatorMap } from "../SharedBrick";
import "@shoelace-style/shoelace/dist/components/icon/icon.js";
import "@shoelace-style/shoelace/dist/components/alert/alert.js";
import { FirebaseApp } from "firebase/app";
import {
  Firestore,
  collection as collectionRef,
  doc as docRef,
  getFirestore,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { GeneralService } from "./general.service";
import { AuthService } from "./auth.service";
import { Observable, firstValueFrom } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

class MessagingService {
  private _firestore: Firestore;
  private _isOptedIn: Observable<boolean>;
  private _phoneNumber: Observable<string | undefined>;

  constructor(
    app: FirebaseApp,
    private _generalService: GeneralService,
    private _authService: AuthService
  ) {
    this._firestore = getFirestore(app);

    this._isOptedIn = this._authService.user.pipe(
      map((user) => {
        return Boolean(user) && Boolean(user?.phoneNumber);
      }),
      shareReplay(1)
    );

    this._phoneNumber = this._authService.user.pipe(
      map((user) => {
        return user?.phoneNumber ?? undefined;
      })
    );
  }

  get optedIn(): Observable<boolean> {
    return this._isOptedIn;
  }

  get phoneNumber(): Observable<string | undefined> {
    return this._phoneNumber;
  }

  async optIn(phoneNumber: string) {
    return this._authService.linkPhoneNumber(phoneNumber);
  }

  async optOut() {
    await this._authService.unlinkPhoneNumber();
  }

  async sendMessages(toUids: string[], body: string) {
    const users = await firstValueFrom(this._generalService.users);
    const batch = writeBatch(this._firestore);
    toUids.forEach((uid) => {
      if (Boolean(users[uid]?.phoneNumber)) {
        console.log("sending message", uid, body);
        batch.set(docRef(collectionRef(this._firestore, "messages")), {
          to: users[uid]?.phoneNumber,
          body,
        });
      }
    });
    await batch.commit();
  }

  async sendMessageToPhoneNumber(phoneNumber: string, body: string) {
    await setDoc(docRef(collectionRef(this._firestore, "messages")), {
      to: phoneNumber,
      body,
    });
  }
}

export async function initializeMessagingService(
  locator: Locator<SharedBrickLocatorMap>
) {
  const [app, generalService, authService] = await locator.getAll([
    "App",
    "GeneralService",
    "AuthService",
  ]);
  return new MessagingService(app, generalService, authService);
}

export type { MessagingService };
