import { Locator } from "@blast/foundations";
import { FirebaseApp } from "firebase/app";
import {
  getBoolean,
  getRemoteConfig,
  RemoteConfig,
  fetchAndActivate,
  ensureInitialized,
} from "firebase/remote-config";

import { SharedBrickLocatorMap } from "shared/SharedBrick";

const DEFAULT_CONFIG = {
  registration: false,
  export: false,
};

class ConfigService {
  private _remoteConfig: RemoteConfig;

  constructor(app: FirebaseApp) {
    this._remoteConfig = getRemoteConfig(app);
    this._remoteConfig.settings.minimumFetchIntervalMillis = import.meta.env
      .PROD
      ? 3600000
      : 300;
    this._remoteConfig.defaultConfig = DEFAULT_CONFIG;
    this._initialize();
  }

  private async _initialize() {
    try {
      await fetchAndActivate(this._remoteConfig);
    } catch (e) {
      console.error(e);
    }
  }

  async getBooleanValue(key: string): Promise<boolean> {
    await ensureInitialized(this._remoteConfig);
    return getBoolean(this._remoteConfig, key);
  }
}

export async function initializeConfigService(
  locator: Locator<SharedBrickLocatorMap>
) {
  const app = await locator.get("App");
  return new ConfigService(app);
}

export type { ConfigService };
