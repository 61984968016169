import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js";
import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library.js";
import { initializeApp } from "firebase/app";
import { development, production } from "@blast/environments";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import { BrickManager, Locator } from "@blast/foundations";
import { SharedBrick, SharedBrickLocatorMap } from "./shared/SharedBrick";
import { HomeBrickLocatorMap } from "./home/HomeBrick";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { Router } from "./shared/router";
import type { ManageBrickLocatorMap } from "./manage/ManageBrick";
import type { SchedulerBrickLocatorMap } from "./scheduler/SchedulerBrick";
import { connectAuthEmulator, getAuth } from "firebase/auth";

export interface AppLocatorMap
  extends SharedBrickLocatorMap,
    HomeBrickLocatorMap,
    ManageBrickLocatorMap,
    SchedulerBrickLocatorMap {
  BrickManager: BrickManager;
  Router: Router;
}

declare global {
  interface Window {
    locator: Locator<AppLocatorMap>;
  }
}

const locator = new Locator<AppLocatorMap>("main", true);

window.locator = locator;

function getEnvironment(authDomain: string | undefined = undefined) {
  console.log(`Running in ${import.meta.env.MODE} mode`);
  if (import.meta.env.MODE === "production") {
    return {
      ...production,
      firebase: {
        ...production.firebase,
        authDomain: authDomain ?? production.firebase.authDomain,
      },
    };
  } else {
    return {
      ...development,
      firebase: {
        ...development.firebase,
        authDomain: authDomain ?? development.firebase.authDomain,
      },
    };
  }
}

async function start(): Promise<void> {
  // @ts-ignore: Property 'UrlPattern' does not exist
  if (!globalThis.URLPattern) {
    await import("urlpattern-polyfill");
  }

  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);
  dayjs.extend(isBetween);

  setBasePath("@shoelace-style/shoelace/dist");

  registerIconLibrary("default", {
    resolver: (name) =>
      `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/icons/${name}.svg`,
  });
  // Initialize firebase

  const enviroment = getEnvironment(window.location.host);
  initializeApp(enviroment.firebase);

  if (import.meta.env.MODE === "emulation") {
    console.log("connecting emulators");
    connectAuthEmulator(getAuth(), window.location.origin);
    connectFunctionsEmulator(getFunctions(), "127.0.0.1", 5001);
    connectFirestoreEmulator(getFirestore(), "127.0.0.1", 8080);
  }

  const brickManager = new BrickManager();

  locator.set("BrickManager", async () => brickManager);

  brickManager.activateBrick(locator, SharedBrick);

  if (!import.meta.env.PROD) {
    const { DebugBrick } = await import("./debug/DebugBrick");
    brickManager.activateBrick(locator, DebugBrick);
  }

  // Import app component
  await import("./x-app");
}

start();
