import { Locator } from "@blast/foundations";
import { SharedBrickLocatorMap } from "../SharedBrick";
import {
  SlDialog,
  SlHideEvent,
  SlInput,
  serialize,
} from "@shoelace-style/shoelace";
import { TemplateResult, html, render } from "lit";
import "@shoelace-style/shoelace/dist/components/icon/icon.js";
import "@shoelace-style/shoelace/dist/components/dialog/dialog.js";
import "@shoelace-style/shoelace/dist/components/input/input.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { map } from "lit/directives/map.js";
import { when } from "lit/directives/when.js";
import "@shoelace-style/shoelace/dist/components/spinner/spinner.js";

export interface SelectDialogOption {
  name: string;
  value: string;
}

class DialogService {
  constructor() {}

  showDialog(
    title: string,
    content: TemplateResult,
    options: { hideHeader: boolean } = { hideHeader: false }
  ) {
    const fragment = document.createDocumentFragment();
    let dispose: () => void;
    let hide: () => void;
    render(
      html`<sl-dialog
        ?no-header=${options.hideHeader}
        label=${title}
        style="font-family: var(--sl-font-sans); --body-spacing: 0 var(--sl-spacing-large) var(--sl-spacing-large) var(--sl-spacing-large);"
        @close=${() => hide()}
      >
        ${content}
      </sl-dialog>`,
      fragment,
      { host: this }
    );

    const dialog = fragment.firstElementChild as SlDialog;
    const hideListener = (e: SlHideEvent) => {
      if (e.target === dialog) {
        dispose();
      }
    };
    hide = () => {
      dialog.hide();
    };
    dialog.addEventListener("sl-after-hide", hideListener);
    dispose = async () => {
      document.body.removeChild(dialog);
      dialog.removeEventListener("sl-after-hide", hideListener);
    };
    document.body.append(dialog);
    requestAnimationFrame(() => {
      dialog.show();
    });
    return hide;
  }

  showLoading(message: string) {
    return this.showDialog(
      message,
      html` <h2
          style="text-align: center; margin-block-end: var(--sl-spacing-large)"
        >
          ${message}
        </h2>
        <sl-spinner
          style="font-size: 3rem; display: block; margin-inline: auto; margin-block: var(--sl-spacing-large)"
        ></sl-spinner>`,
      { hideHeader: true }
    );
  }

  showPrompt(
    message: string,
    type: SlInput["type"],
    placeholder?: SlInput["placeholder"]
  ): Promise<string> {
    const fragment = document.createDocumentFragment();
    let dispose: () => void;
    let dataResolver: (input: string) => void;
    let data = new Promise<string>((res) => {
      dataResolver = res;
    });
    render(
      html`<sl-dialog
        no-header
        style="font-family: var(--sl-font-sans)"
        @sl-request-close=${(e: Event) => e.preventDefault()}
      >
        <form
          @submit=${(e: SubmitEvent) => {
            e.preventDefault();
            if ((e.target as HTMLFormElement).reportValidity()) {
              dispose();
              dataResolver(
                serialize(e.target as HTMLFormElement).input as string
              );
            }
          }}
        >
          <sl-input
            label=${message}
            type=${type}
            placeholder=${ifDefined(placeholder)}
            autocomplete="off"
            autocapitalize="off"
            name="input"
            required
          ></sl-input>
          <sl-button
            slot="footer"
            type="submit"
            style="margin-block-start: var(--sl-spacing-medium)"
            >Submit</sl-button
          >
        </form>
      </sl-dialog>`,
      fragment,
      { host: this }
    );
    const dialog = fragment.firstElementChild as SlDialog;

    dispose = async () => {
      await dialog.hide();
      document.body.removeChild(dialog);
    };

    document.body.append(dialog);
    requestAnimationFrame(() => {
      dialog.show();
    });

    return data;
  }

  showSelect(
    message: string,
    options: SelectDialogOption[],
    cancelable = false
  ): Promise<string | undefined> {
    const fragment = document.createDocumentFragment();
    let dispose: () => void;
    let dataResolver: (input: string | undefined) => void;
    let data = new Promise<string | undefined>((res) => {
      dataResolver = res;
    });
    render(
      html`<sl-dialog
        no-header
        style="font-family: var(--sl-font-sans)"
        @sl-request-close=${(e: Event) => e.preventDefault()}
      >
        <form
          @submit=${(e: SubmitEvent) => {
            e.preventDefault();
            if ((e.target as HTMLFormElement).reportValidity()) {
              dispose();
              dataResolver(
                serialize(e.target as HTMLFormElement).input as string
              );
            }
          }}
        >
          <sl-select required name="input" label=${message} hoist>
            ${map(
              options,
              (option) =>
                html`<sl-option value=${option.value}
                  >${option.name}</sl-option
                >`
            )}
          </sl-select>
          ${when(
            cancelable,
            () => html`<sl-button
              slot="footer"
              @click=${() => {
                dispose();
                dataResolver(undefined);
              }}
              style="margin-block-start: var(--sl-spacing-medium)"
            >
              Cancel
            </sl-button>`
          )}
          <sl-button
            slot="footer"
            type="submit"
            style="margin-block-start: var(--sl-spacing-medium)"
          >
            Submit
          </sl-button>
        </form>
      </sl-dialog>`,
      fragment,
      { host: this }
    );
    const dialog = fragment.firstElementChild as SlDialog;

    dispose = async () => {
      await dialog.hide();
      document.body.removeChild(dialog);
    };

    document.body.append(dialog);
    requestAnimationFrame(() => {
      dialog.show();
    });

    return data;
  }

  showConfirm(
    message: string,
    cancelText: string = "Cancel",
    confirmText: string = "Confirm"
  ): Promise<boolean> {
    const fragment = document.createDocumentFragment();
    let dispose: () => void;
    let dataResolver: (input: boolean) => void;
    let data = new Promise<boolean>((res) => {
      dataResolver = res;
    });
    render(
      html`<sl-dialog
        style="font-family: var(--sl-font-sans)"
        label=${message}
        @sl-request-close=${() => {
          dataResolver(false);
        }}
      >
        <sl-button
          slot="footer"
          variant="default"
          @click=${() => {
            dispose();
            dataResolver(false);
          }}
        >
          ${cancelText}
        </sl-button>
        <sl-button
          slot="footer"
          variant="success"
          @click=${() => {
            dispose();
            dataResolver(true);
          }}
        >
          ${confirmText}
        </sl-button>
      </sl-dialog>`,
      fragment,
      { host: this }
    );
    const dialog = fragment.firstElementChild as SlDialog;

    dispose = async () => {
      await dialog.hide();
      document.body.removeChild(dialog);
    };

    document.body.append(dialog);
    requestAnimationFrame(() => {
      dialog.show();
    });

    return data;
  }
}

export async function initializeDialogService(
  _locator: Locator<SharedBrickLocatorMap>
) {
  return new DialogService();
}

export type { DialogService };
