import { Brick, BrickContext, Locator } from "@blast/foundations";
import { FirebaseApp, getApp } from "firebase/app";
import { AuthService, initializeAuthService } from "./services/auth.service";
import { ToastService, initializeToastService } from "./services/toast.service";
import {
  DialogService,
  initializeDialogService,
} from "./services/dialog.service";
import {
  AnalyticsService,
  initializeAnalyticsService,
} from "./services/analytics.service";
import {
  CloudinaryService,
  initializeCloudinaryService,
} from "./services/cloudinary.service";
import {
  MessagingService,
  initializeMessagingService,
} from "./services/messaging.service";
import {
  GeneralService,
  initializeGeneralService,
} from "./services/general.service";
import {
  ConfigService,
  initializeConfigService,
} from "./services/config.service";

export interface SharedBrickLocatorMap {
  App: FirebaseApp;
  AuthService: AuthService;
  ToastService: ToastService;
  DialogService: DialogService;
  AnalyticsService: AnalyticsService;
  CloudinaryService: CloudinaryService;
  MessagingService: MessagingService;
  GeneralService: GeneralService;
  ConfigService: ConfigService;
}

export class SharedBrick implements Brick<SharedBrickLocatorMap> {
  static readonly id = "shared";

  async onActivate({
    locator,
  }: BrickContext<SharedBrickLocatorMap>): Promise<void> {
    console.log("activating shared brick");
    await Promise.all([this._registerServices(locator)]);
  }

  private async _registerServices(locator: Locator<SharedBrickLocatorMap>) {
    locator.set("App", async () => getApp());
    locator.set("AuthService", initializeAuthService);
    locator.set("GeneralService", initializeGeneralService);
    locator.set("ToastService", initializeToastService);
    locator.set("DialogService", initializeDialogService);
    locator.set("AnalyticsService", initializeAnalyticsService);
    locator.set("CloudinaryService", initializeCloudinaryService);
    locator.set("MessagingService", initializeMessagingService);
    locator.set("ConfigService", initializeConfigService);
  }
}
