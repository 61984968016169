import { Locator } from "@blast/foundations";
import { SharedBrickLocatorMap } from "../SharedBrick";
import { SlAlert } from "@shoelace-style/shoelace";
import { when } from "lit/directives/when.js";
import { html, render } from "lit";
import "@shoelace-style/shoelace/dist/components/icon/icon.js";
import "@shoelace-style/shoelace/dist/components/alert/alert.js";

function escapeHtml(html: string) {
  const div = document.createElement("div");
  div.textContent = html;
  return div.innerHTML;
}

class ToastService {
  constructor() {}

  showToast(
    variant: SlAlert["variant"],
    duration: SlAlert["duration"],
    message: string,
    icon?: string
  ) {
    const fragment = document.createDocumentFragment();
    render(
      html`<sl-alert variant=${variant} duration=${duration} closable>
        ${when(
          icon,
          () => html`<sl-icon name="${icon!}" slot="icon"></sl-icon>`
        )}
        ${escapeHtml(message)}
      </sl-alert>`,
      fragment
    );
    const alert = fragment.firstElementChild as SlAlert;

    document.body.append(alert);
    return alert.toast();
  }

  showErrorToast(message: string) {
    this.showToast("danger", 5000, message, "exclamation-diamond-fill");
  }

  showSuccessToast(message: string) {
    this.showToast("success", 5000, message, "check2");
  }
}

export async function initializeToastService(
  _locator: Locator<SharedBrickLocatorMap>
) {
  return new ToastService();
}

export type { ToastService };
