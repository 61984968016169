import { Locator } from "@blast/foundations";
import { SharedBrickLocatorMap } from "../SharedBrick";
import { FirebaseApp } from "firebase/app";

class AnalyticsService {
  constructor(_app: FirebaseApp) {}

  log(_eventName: string, _params?: Record<string, unknown>) {
    // noop
  }
}

export async function initializeAnalyticsService(
  locator: Locator<SharedBrickLocatorMap>
) {
  const app = await locator.get("App");
  return new AnalyticsService(app);
}

export type { AnalyticsService };
