import { Locator } from "@blast/foundations";
import { FirebaseApp } from "firebase/app";
import {
  getFirestore,
  Firestore,
  doc as docRef,
  collection as collectionRef,
  query,
  where,
} from "firebase/firestore";
import { collection, doc } from "rxfire/firestore";
import { combineLatest, Observable, of } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { SharedBrickLocatorMap } from "shared/SharedBrick";
import { BlastUser, CastMember, Number } from "@blast/models";
import { AuthService } from "shared/services/auth.service";

class GeneralService {
  private _firestore: Firestore;
  private _roles: Observable<Number[] | undefined>;
  private _numbers: Observable<Number[] | undefined>;
  private _users;

  constructor(app: FirebaseApp, authService: AuthService) {
    this._firestore = getFirestore(app);
    const numbers = collectionRef(this._firestore, "numbers");

    this._roles = authService.user.pipe(
      switchMap((user) => {
        const uid = user?.uid;
        if (uid) {
          const numbersRef = collectionRef(this._firestore, "numbers");
          const q = query(
            numbersRef,
            where("castKeys", "array-contains", uid),
            where("meta.active", "==", true)
          );
          return collection(q).pipe(
            map((docs) => docs.map((doc) => doc.data() as Number)),
            take(1),
            shareReplay(1)
          );
        }
        return of(undefined);
      }),
      shareReplay(1)
    );

    const usersDoc = docRef(this._firestore, "general/users");
    this._users = authService.claims.pipe(
      switchMap((claims) => {
        if (
          claims.length > 0 &&
          (claims.includes("admin") || claims.includes("leader"))
        ) {
          console.log("User is admin getting up to date user references!");
          return doc(usersDoc).pipe(
            map((doc) => {
              const { users } = doc.data() as {
                users: {
                  [uid: string]: Pick<
                    BlastUser,
                    "displayName" | "email" | "photoURL" | "phoneNumber"
                  >;
                };
              };
              return users;
            }),
            shareReplay(1)
          );
        } else {
          console.log("Generic user, throttling user references");
          return doc(usersDoc).pipe(
            map((doc) => {
              const { users } = doc.data() as {
                users: {
                  [uid: string]: Pick<
                    BlastUser,
                    "displayName" | "email" | "photoURL" | "phoneNumber"
                  >;
                };
              };
              return users;
            }),
            take(1),
            shareReplay(1)
          );
        }
      })
    );

    this._numbers = combineLatest([
      collection(query(numbers, where("meta.active", "==", true))).pipe(
        take(1),
        shareReplay(1)
      ),
      this._users,
    ]).pipe(
      map(([docs, users]) =>
        docs
          .map((doc) => doc.data() as Number)
          .map((number) => {
            Object.keys(number.cast).forEach((cast) => {
              number.cast[cast] = {
                ...number.cast[cast],
                displayName: users[cast]?.displayName ?? "Unknown user",
              } as CastMember & { displayName: string };
            });
            return number;
          })
      ),
      shareReplay(1)
    );
  }

  get users() {
    return this._users;
  }

  get roles() {
    return this._roles;
  }

  get numbers() {
    return this._numbers;
  }
}

export async function initializeGeneralService(
  locator: Locator<SharedBrickLocatorMap>
) {
  const [app, authService] = await locator.getAll(["App", "AuthService"]);
  return new GeneralService(app, authService);
}

export type { GeneralService };
