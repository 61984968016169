import { Locator } from "@blast/foundations";
import { SharedBrickLocatorMap } from "../SharedBrick";
import { Cloudinary } from "@cloudinary/url-gen";
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
import { face } from "@cloudinary/url-gen/qualifiers/focusOn";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";

class CloudinaryService {
  private readonly bucketName = "blast35";
  private _cloudinary: Cloudinary;
  constructor() {
    this._cloudinary = new Cloudinary({
      cloud: {
        cloudName: "wchs-blast",
      },
    });
  }

  getAvatar(publicId: string) {
    return this._cloudinary
      .image(`${this.bucketName}/${publicId}`)
      .resize(thumbnail().width(200).height(200).gravity(focusOn(face())));
  }

  getAvatarURL(publicId: string) {
    return this.getAvatar(publicId).toURL();
  }

  getVideo(publicId: string) {
    return this._cloudinary.video(`${this.bucketName}/${publicId}`);
  }

  getVideoURL(publicId: string) {
    return this.getVideo(publicId).toURL();
  }

  getVocalVideoId(blastId: string) {
    return `${this.bucketName}/${blastId}_vocal`;
  }

  getDanceVideoId(group: string) {
    return `${this.bucketName}/${group}_dance`;
  }
}

export async function initializeCloudinaryService(
  _locator: Locator<SharedBrickLocatorMap>
) {
  return new CloudinaryService();
}

export type { CloudinaryService };
